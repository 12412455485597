import React, { useCallback, useRef, useState } from "react";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch } from "react-redux";
import debounce from 'lodash.debounce';
import {
    Link,
    MenuItem,
    MenuList,
    Box,
    Typography,
    Button,
    ButtonGroup,
    Input,
    InputAdornment,
    FormControl,
    Popper,
    ClickAwayListener,
    Grow,
    Paper,
    CircularProgress
} from "@mui/material";

import images from "../../assets/js/images";
import { BeachGearService } from "../../Services/BeachGearService";
import { HomePageService } from "../../Services/HomePageService";
import SelectInput from "../Inputs/SelectInput";
import QuantityInput from "../Inputs/QuantityInput";
import AppHelper from "../../Helpers/AppHelper";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ActivitiesPopover = ({ pagetype, clearAllData, anchorActivitiesEl, handleCloseActivities }) => {
    const dispatch = useDispatch();
    const navigate = useHistory();
    const location = useLocation();

    const serviceSearch = useRef(null);
    const getGearData = (search = '', services = []) => {
        setLoader(true);
        HomePageService.searchServiceFilter(search, categoryId).then(res => {
            var response = res.data.data;
            
            if (services.length) {
                Object.keys(response).map((vv, ii) => {
                    response[vv].map((v, i) => {
                        var servicesData = services.find(o => o.id == v.id)

                        if (servicesData) {
                            response[vv][i].count = servicesData.count;
                        }
                    })
                })
            }

            setGearData(response);
            setLoader(false);

            setTimeout(() => {
                //serviceSearch.current.focus();
            }, 500);
        })
    }

    const activitiesOpen = Boolean(anchorActivitiesEl);
    const debounceGear = debounce((value) => {
        handleGearData(value);
    }, 1000);

    const [categoryId, setCategoryId] = useState(1);
    const [gearData, setGearData] = useState([]);
    const [loader, setLoader] = useState(false);

    const [beachgearcategorieslist, setBeachGearCategoriesList] = React.useState([]);
    const getBeachGearCategoriesList = () => {
        BeachGearService.beachGearCategoriesList(1).then(res => {
            setBeachGearCategoriesList(res.data.data);
        }).catch(error => {
        });
    };

    const [searchActivities, setSearchActivities] = useState(sessionStorage.getItem('searchActivities') != null ? sessionStorage.getItem('searchActivities') : '');
    React.useEffect(() => {
        setSearchActivities(sessionStorage.getItem('searchActivities') != null ? sessionStorage.getItem('searchActivities') : '');
    }, [sessionStorage.getItem('searchActivities')])
    
    React.useEffect(() => {
        getBeachGearCategoriesList();
        handleGearData(searchActivities);
    }, [categoryId]);
    
    const handleGearData = (value) => {
        var searchStorage = sessionStorage.getItem('searchItems') != null ? JSON.parse(sessionStorage.getItem('searchItems')).data : {};    
        getGearData(value, (searchStorage.services != '') ? searchStorage.services : []);
    }

    const clearAll = () => {
        dispatch({
            type: 'SET_SERVICES',
            payload: ''
        });

        sessionStorage.removeItem('searchActivities')
        if(serviceSearch.current?.value!=''){
            serviceSearch.current.value = '';
        } 
        setCategoryId(0)
        setTimeout(() => { setCategoryId(1) }, 100)
    }

    const saveSearch = (e) => {
        if (location.pathname == '/search-result') {
            handleCloseActivities(e)
            navigate.push('/search-result?search=' + AppHelper.searchRandomNumber());
            return;
        }

        navigate.push('/search-result');
    }

    React.useEffect(() => {
        if (clearAllData == 1) {
            clearAll()
        }
    }, [clearAllData]);

    const handlegearchange = (value, id, v) => {
        let index = gearData[v].findIndex(o => o.id == id);
        
        let data = {...gearData};
        data[v][index].count = value;
        setGearData(data);
        
        dispatch({
            type: 'SET_SERVICES',
            payload: { id: id, count: value },
        });
        };

    const gearItemsList = (gData, index, type) => {
        return (
            <MenuItem
                key={index}
                sx={{
                    backgroundColor: "transparent !important",
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }}
            >
                <Box
                    component="div"
                    className="beachgear-value-input"
                >
                    <QuantityInput
                        quantity={gData.count}
                        decrement={() => {
                            handlegearchange(
                                gData.count - 1 > 0 ? gData.count - 1 : 0,
                                gData.id,
                                type
                            );
                        }}
                        increment={() => {
                            handlegearchange(
                                gData.count + 1,
                                gData.id,
                                type
                            );
                        }}
                    />
                    <Typography
                        component="label"
                        className="text-truncate"
                    >
                        {gData.name}
                    </Typography>
                </Box>
            </MenuItem>
        )
    }

    const gearItems = () => {
        return (
            <MenuList
                autoFocusItem={open}
                id="search-bear-menu"
                aria-labelledby="search-bear-button"
                //onKeyDown={handleListKeyDown}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{ py: 0 }}
            >
                {!loader ?
                    Object.keys(gearData).length > 0 ?
                        Object.keys(gearData).map((v, i) => 
                            {return (
                                v=='Regular'
                                ?
                                gearData[v].map((gData, index) => (
                                    gearItemsList(gData, index, v)
                                ))
                                :
                                <Accordion defaultExpanded={searchActivities != ''} sx={{ 'marginBottom' : '15px'}}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    >
                                    <Typography>{v}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        { gearData[v].map((gData, index) => (
                                            gearItemsList(gData, index, v)
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        ) :
                        <Typography sx={{
                            textAlign: 'center'
                        }}>No Records Found</Typography>

                    :
                    <Typography sx={{
                        textAlign: 'center'
                    }}><CircularProgress /> </Typography>

                }

            </MenuList>
        )
    }

    return (
        <>
            {pagetype && pagetype == 'searchresult'
                ?
                <>
                    <Box
                        noValidate
                        autoComplete="off"
                        className='bottom-space'
                    >
                        <FormControl variant="standard" className="input-style outlined-input select-style border-radius-md" sx={{
                            width: '100%',
                            paddingLeft: '30px',
                        }}>
                            <SelectInput
                                customObj={beachgearcategorieslist}
                                name="category_id"
                                value={categoryId}
                                onChange={(e) => {
                                    setCategoryId(e.target.value)
                                }}
                                renderValue={(selected) => {
                                    if (selected == undefined || selected == '') {
                                        return <em>Category</em>;
                                    }
                                    return beachgearcategorieslist[categoryId];
                                }}
                            />
                        </FormControl>
                    </Box>
                    {gearItems()}
                </>
                :
                <Popper
                    open={activitiesOpen}
                    anchorEl={anchorActivitiesEl}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal={false}
                    className="filter-popper"
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom-start"
                                        ? "left top"
                                        : "left bottom",
                            }}
                        >
                            <Paper className="filter-popper-paper">
                                <ClickAwayListener onClickAway={handleCloseActivities}>
                                    <div>
                                        <Scrollbars
                                            className="filter-scrollbar"
                                            autoHeight
                                            autoHeightMin={0}
                                            autoHeightMax={450}
                                        >
                                            <Box className="search-filter-btn">
                                                <FormControl
                                                    variant="standard"
                                                    className="input-style outlined-input w-100"
                                                    underline="none"
                                                >
                                                    <Input
                                                        inputRef={serviceSearch}
                                                        id="input-with-icon-adornment"
                                                        placeholder="Search"
                                                        underline="none"
                                                        disableUnderline={true}
                                                        defaultValue={searchActivities}
                                                        onChange={(e) => {
                                                            var activitiesValue = e.target.value;
                                                            debounceGear(activitiesValue);
                                                            sessionStorage.setItem('searchActivities', activitiesValue);
                                                        }}
                                                        startAdornment={
                                                            <InputAdornment
                                                                position="start"
                                                                className="input-image"
                                                                underline="none"
                                                                sx={{ pl: 1.5, pr: 1 }}
                                                            >
                                                                <img
                                                                    src={images.searchIcon}
                                                                    alt="search"
                                                                />
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                                <Box
                                                    sx={{  
                                                        display: 'grid',  
                                                        gap: '15px', 
                                                        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', 
                                                        width: '100%', 
                                                        '& button':{ 
                                                            width: '100%',
                                                        },
                                                        '@media (min-width: 0) and (max-width: 474.98px)':{
                                                            gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
                                                        },
                                                        '@media (min-width: 475px) and (max-width: 810.98px)':{
                                                            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                                                        },
                                                        '@media (min-width: 811px) and (max-width: 991.98px)':{
                                                            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                                                        },
                                                        '@media (min-width: 992px) and (max-width: 1199px)':{
                                                            gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
                                                        },
                                                    }}>
                                                    {
                                                        (beachgearcategorieslist != '' && beachgearcategorieslist != undefined) &&
                                                        Object.keys(beachgearcategorieslist).map((i) => (
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => setCategoryId(i)}
                                                                    className={
                                                                        categoryId == i ?
                                                                            "border-radius-md btn-primary small-btn" :
                                                                            "border-radius-md secondary-btn small-btn"
                                                                    }
                                                                >
                                                                    {beachgearcategorieslist[i]}
                                                                </Button>
                                                        ))
                                                    }
                                                </Box>
                                            </Box>
                                            {gearItems()}
                                        </Scrollbars>
                                        <Box className="filter-menu-footer">
                                            <Link
                                                onClick={clearAll}
                                                href="javascript:void(0);"
                                                className="link-secondary text-underline clear-btn"
                                            >
                                                Clear all
                                            </Link>
                                            <Link
                                                type="submit"
                                                variant="contained"
                                                className="border-radius-md btn-primary save-btn"
                                                onClick={saveSearch}
                                                style={{
                                                    padding: '6px 16px',
                                                    display: 'inline-flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '16px'
                                                }}
                                            >
                                                Save & Search
                                            </Link>
                                        </Box>
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            }
        </>
    );
}

export default ActivitiesPopover;